import React from "react";

const ServiceContentTwo = () => {
  return (
    <div className="dg__secure__transaction--2 secure--2 section-padding--xl">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7 col-md-12 col-sm-12 col-12">
            <div className="dg__secure__inner">
              <h2 className="title__with__border">How It Works</h2>
              <p>
                Customers scan QR codes or tap through links in text and email
                messages to arrive at Tipping-Token, secure payment screen.
                After submitting the tip, the customer can leave a star rating
                and feedback for your services. The payment posts to your Wallet
                Immediately.
              </p>
            </div>
          </div>
          <div className="col-lg-5 col-md-12 col-sm-12 col-12 sm__mt--40 md__mt--40">
            <div className="dg__secure__thumb">
              <img
                src={process.env.PUBLIC_URL + "/images/service/md-img/1.png"}
                alt="secure images"
              />
              <div className="shape-2">
                <img
                  src={process.env.PUBLIC_URL + "/images/service/md-img/2.png"}
                  alt="service images"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceContentTwo;
