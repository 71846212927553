import React from "react";
// import ModalVideo from "react-modal-video";

const VideoPopup = () => {
  // const [modalStatus, isOpen] = useState(false);
  return (
    <div
      className="dg__video__area"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + "/images/bg/8.jpg"})`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="dg__video__inner">
              <h2>Our Mission</h2>
              <div className="d-flex justify-content-center mb-4">
                <ul className="text-white text-left">
                  <li>
                    To be the simplest, quickest substitute for cash in tipping
                    situations
                  </li>
                  <li>
                    To provide a system that benefits businesses, workers, and
                    tippers
                  </li>
                  <li>
                    To integrate wherever possible (ie PMS, POS, apps, etc) to
                    accommodate tipping transactions
                  </li>
                  <li>
                    To evolve in parallel with payment and feedback technology
                  </li>
                </ul>
              </div>
              {/* <div className="play__video">
                <ModalVideo
                  channel="youtube"
                  isOpen={modalStatus}
                  videoId="2X9eJF1nLiY"
                  onClose={() => isOpen(false)}
                />
                <button
                  onClick={() => isOpen(true)}
                  className="play__btn  text-left"
                >
                  <img
                    src={process.env.PUBLIC_URL + "/images/about/play.png"}
                    alt="play icon"
                  />
                  Play Now
                </button>
              </div>*/}
            </div> 
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPopup;
