import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import UserWalletContent from "../components/user-wallet/UserWalletContent";
const UserWalletByUserName = () => {
  let { userName,userId } = useParams();
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await axios
        .get(
          `https://1ui1wl9lq0.execute-api.us-east-2.amazonaws.com/dev/user/?username=${userName}`
        )
        .then((response) => {
          console.log(response);
          setUserData(response.data.Data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    })();
  }, [userName,userId]);
  return (
    <Fragment>
      <MetaTags>
        <title>Tipping-Token | User Wallet</title>
        <meta
          name="description"
          content="User Wallet page of Tipping-Token Website."
        />
      </MetaTags>
      <div>
        {/* breadcrumb */}
        {loading && (
          <div className="page-loader d-flex align-items-center justify-content-center">
            <div className="d-flex align-self-center mx-auto">
              <Spinner
                className="mr-1 page-spinner"
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
            </div>
          </div>
        )}
        {userData!==""&&userData!==undefined&&(<UserWalletContent data={userData}/>)}
     </div>
    </Fragment>
  );
};

export default UserWalletByUserName;
