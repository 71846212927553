import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutTwo from "../layouts/LayoutTwo";
import Breadcrumb from "../components/breadcrumbs/Breadcrumb";
import SearchUserContent from "../components/search-user/SearchUserContent";

const SearchUser = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>Tipping-Token | Tip Search</title>
        <meta name="description" content="Tip Search of Tipping-Token Website." />
      </MetaTags>
      <LayoutTwo theme="white">
        {/* breadcrumb */}
        <Breadcrumb title="Tip Search" />
        {/* about content */}
        <SearchUserContent />
      </LayoutTwo>
    </Fragment>
  );
};

export default SearchUser;
