const baseURL =
  'https://1ui1wl9lq0.execute-api.us-east-2.amazonaws.com/dev/user/';

export const fetchUserByWallet = async (wallet) => {
  return await fetch(`${baseURL}?wallet=${wallet}`, {
    method: 'get',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then((result) => result.Data)
    .catch((err) => err);
};
