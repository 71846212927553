import React from "react";
import StarRatingComponent from "react-star-rating-component";
import Logo from "../header/Logo";

const UserWalletContent = (data) => {

  
  return (
    <div className="container user-wallet-content  pt--30 pb--130">
    <div className="main-body">
    <div className="row">
        <div className="col d-flex justify-content-center mb-4">
    <Logo
        image={ "/images/logo/1.png"}
      />
      </div>
      </div>
      <div className="row">
        <div className="col-lg-4 card">
          <div className="card-body">
            <div className="d-flex flex-column align-items-center text-center">
              <img
                src={data.data.profileImage}
                alt="userImage"
                className="rounded-circle p-1 bg-primary"
                width="110"
              />
              <div className="mt-3">
                <h4>
                  {data.data.firstName}
                  {"  "}
                  {data.data.lastName}
                </h4>

                <StarRatingComponent
                  name="rate2"
                  editing={false}
                  renderStarIcon={() => (
                    <span style={{ fontSize: "24px" }}>★</span>
                  )}
                  starCount={5}
                  value={data.data.rating}
                />
              </div>
            </div>
            <hr className="my-4" />
            <div className="row mb-3">
              <div className="col-8 text-secondary">
                <input
                  type="number"
                  className="form-control"
                  value="Tip"
                />
              </div>
              <div className="col-4">
                <button className="btn btn-primary w-100">Tip</button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-8 card">
          <div className="card-body px-5">
            <div className="row mb-3">{data.data.story}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  );
};

export default UserWalletContent;
