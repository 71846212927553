import React from "react";

const ServiceContentThree = () => {
  return (
    <section className="dg__service__area bg--white padding--hor">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5">
            <div className="service__chart">
              <div className="thumb">
                <img
                  src={process.env.PUBLIC_URL + "/images/about/service.png"}
                  alt="service images"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-xl-6 offset-xl-1 md__mt--40 sm__mt--40">
            <div className="service__inner">
              <h3 className="title__with__border">Build your brand.</h3>
              <p>
                Our white label Powered by our program lets you present our
                tipping screens with your logo, and branding, whether you
                integrate our services with your existing branded app, use our
                system as a standalone solution, or combine the two.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceContentThree;
