import React, { useState } from 'react';

import { toast } from 'react-toastify';
import validator from 'validator';

import Stellar from '../../helpers/Stellar';
import { fetchUserByWallet } from '../../helpers/User';

function Faucet() {
  const IntialInputs = () => ({
    inputs: {
      amount: '',
      publicKey: '',
    },
  });
  const handleChange = (e) => {
    const { value, name } = e.target;
    const { inputs } = state;

    inputs[name] = value;
    setState({
      ...state,
      inputs,
    });
  };
  const [state, setState] = useState(IntialInputs());
  const [sendClicked, setSendClicked] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  const saveTransfer = async (e) => {
    e.preventDefault();
    setShowErrors(true);
    if (state.inputs.amount !== '' && state.inputs.publicKey !== '') {
      setSendClicked(true);
      await transferTipToken();
      // console.log(state.inputs.publicKey);
      toast.success('Your data saved successfully');
      setState(IntialInputs);
      setShowErrors(false);
      setSendClicked(false);
    }
  };
  const transferTipToken = async () => {
    try {
      // replace it recipient state
      const accountExists = await Stellar.accountExists(state.inputs.publicKey);
    } catch (error) {
      error = error.message;
      if (error.message === 'Request failed with status code 404') {
        alert(
          'Destination account not funded.',
          `The account provided is not created yet. You need to make a "Create Account" operation in order to create it`
        );
      } else if (error.message === 'Network Error') {
        alert(
          'Network issue detected.',
          "We couldn't reach the server. Check your internet connection."
        );
      } else {
        alert('Key Error', 'The key provided is not a valid Stellar Key.');
      }
      return;
    }
    let result;
    try {
      const currentUserWalletDet = await fetchUserByWallet(
        state.inputs.publicKey
      );

      result = await Stellar.submitTransaction(
        'GBM3THBYELDNN52WTYUPSRVXGC32XAMIELCOUBQJH2ZLGAKYQXTHZCQB', // source wallet
        'SBKHTDZM2COQA6PB5OQ6R4TPPNYEZOLMEJSNUACPPZEM67PGYSEBZRQJ', // source wallet secret
        state.inputs.publicKey, // source wallet private key
        currentUserWalletDet.privateKey, //destination wallet private key
        state.inputs.amount, //amount
        'TTIP', //asset code
        'GBDDTGDRX77CIL3E6BYCRKS2OSAO2FQ3BIDKTCOXOKTFBJ4N2H3QSVKO',
        '100', // fee
        null // memo
      );
    } catch (error) {
      alert(
        'Network issue detected.',
        "We couldn't reach the server. Check your internet connection."
      );
    }

    if (result) {
      try {
        const transactionDetails = await fetch(result._links.transaction.href);
        const transactionResultData = await transactionDetails.json();
        console.log(transactionResultData);
      } catch (error) {
        alert(
          'Error loading transaction details.',
          'Transaction submitted successfully, but error loading details. Check your operations log to confirm.'
        );
      }
    }
  };
  return (
    <div className='dg__account section-padding--xl'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='single__account'>
              <div className='input__box'>
                <span>Amount</span>
                <input
                  type='number'
                  name='amount'
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={state.inputs.amount}
                />
                {showErrors === true &&
                  validator.isEmpty(state.inputs.amount) && (
                    <div className='error-message'>Required</div>
                  )}
              </div>
              <div className='input__box'>
                <span>Public Key</span>
                <input
                  type='text'
                  name='publicKey'
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={state.inputs.publicKey}
                />
                {showErrors === true &&
                  validator.isEmpty(state.inputs.publicKey) && (
                    <div className='error-message'>Required</div>
                  )}
              </div>
              <div className={sendClicked ? 'disabled-container' : ''}>
                <button
                  className='account__btn'
                  onClick={saveTransfer}
                  loading={sendClicked.toString()}
                  disabled={sendClicked}
                >
                  Faucet
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faucet;
