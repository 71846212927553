import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./assets/scss/style.scss";
import * as serviceWorker from "./serviceWorker";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
ReactDOM.render(<App />, document.getElementById("root"));
toast.configure({
    position: 'bottom-right',
    autoClose: 10000,
    draggable: false,
    className: 'toaster',
  });
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
