import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutTwo from "../layouts/LayoutTwo";
import Breadcrumb from "../components/breadcrumbs/Breadcrumb";
import ServiceContent from "../components/service-contents/ServiceContent";
import ServiceList from "../containers/service-contents/ServiceList";

const Product = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>Tipping-Token | Product</title>
        <meta
          name="description"
          content="Service page of Tipping-Token Website."
        />
      </MetaTags>
      <LayoutTwo theme="white">
        {/* breadcrumb */}
        <Breadcrumb title="Tipping-Token Solution" />
        {/* service content */}
        <ServiceContent />
        {/* service list */}
        <ServiceList />
      </LayoutTwo>
    </Fragment>
  );
};

export default Product;
