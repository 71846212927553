import React from "react";
import { Link } from "react-router-dom";
import ReactWOW from "react-wow";

const SecureTransaction = () => {
  return (
    <div className="dg__secure__transaction">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7">
            <div className="dg__secure__inner">
              <h2>Tipping-Token system features</h2>
              <p>
                <b>Just Scan & Tip:</b> Direct access to digital wallets. Our
                Scan & Tip system facilitates a super quick & simple transaction
                using digital wallets.
              </p>
              <p>
                <b>Simple, Powerful Feedback Tool:</b> Our post-tip feedback
                screen lets customers rate employees, service levels, and leave
                helpful feedback. Ratings can be 1-5 stars.
              </p>
              <p>
                <b>Versatile & Pervasive:</b> Customers may access tipping
                screens on their phones by scanning QR codes. QR codes can
                reflect the company’s branding and be placed anywhere from
                inside hotel rooms to employee badges, to valet stands.
              </p>
              <Link
                className="secure__btn dg__btn btn--trans"
                to={process.env.PUBLIC_URL + "/login-register"}
              >
                Get started
              </Link>
            </div>
          </div>
          <div className="col-lg-5">
            <ReactWOW duration="2s" animation="fadeInRight">
              <div className="dg__secure__thumb">
                <img
                  src={process.env.PUBLIC_URL + "/images/about/2.png"}
                  alt="secure images"
                />
              </div>
            </ReactWOW>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecureTransaction;
