import React from "react";
import { Link } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";

const Navigation = () => {
  return (
    <nav className="mainmenu__nav">
      <ul className="mainmenu">
        <li className="drop">
          <Link to={process.env.PUBLIC_URL + "/"}>
            Home 
          </Link>
        </li>
        <li className="drop">
          <Link to={process.env.PUBLIC_URL + "/product"}>
          Service <IoIosArrowDown />
          </Link>
          <ul className="dropdown">
            <li>
              <Link to={process.env.PUBLIC_URL + "/product"}>Product</Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/how-it-works"}>
              How it works
              </Link>
            </li>
          </ul>
        </li>
        {/* <li className="drop">
          <Link to={process.env.PUBLIC_URL + "/blog"}>
            News <IoIosArrowDown />
          </Link>
          <ul className="dropdown">
            <li>
              <Link to={process.env.PUBLIC_URL + "/blog"}>Blog Page</Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/blog-right-sidebar"}>
                Blog Right Sidebar
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/blog-post"}>
                Blog Details
              </Link>
            </li>
          </ul>
        </li> */}
        <li className="drop">
          <Link to={process.env.PUBLIC_URL + "#/"}>
            Pages <IoIosArrowDown />
          </Link>
          <ul className="dropdown">
          <li>
              <Link to={process.env.PUBLIC_URL + "/about"}>About Us</Link>
            </li>
            {/* <li>
              <Link to={process.env.PUBLIC_URL + "/pricing"}>
              Pricing
              </Link>
            </li> */}
            <li>
              <Link to={process.env.PUBLIC_URL + "/search"}>
              Tip Search
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/faucet-page"}>
              Faucet Page
              </Link>
            </li>
           
          </ul>
        </li>
        {/* <li>
          <Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link>
        </li> */}
      </ul>
    </nav>
  );
};

export default Navigation;
