import React from "react";
import { Switch, Route, HashRouter } from "react-router-dom";
import ScrollToTop from "./helpers/ScrollToTop";
import HomeOne from "./pages/HomeOne";
import HomeTwo from "./pages/HomeTwo";
import HomeThree from "./pages/HomeThree";
import HomeFour from "./pages/HomeFour";
import About from "./pages/About";
import Blog from "./pages/Blog";
import BlogRightSidebar from "./pages/BlogRightSidebar";
import BlogPost from "./pages/BlogPost";
import Contact from "./pages/Contact";
import Product from "./pages/Product";
import HowItWorks from "./pages/HowItWorks";
import Merchants from "./pages/Merchants";
import Team from "./pages/Team";
import Wallet from "./pages/Wallet";
import LoginRegister from "./pages/LoginRegister";
import NotFound from "./pages/NotFound";
import Pricing from "./pages/Pricing";
import Profile from "./pages/Profile";
import UserWallet from "./pages/UserWallet";
import UserWalletByUserName from "./pages/UserWalletByUserName";
import FaucetPage from "./pages/FaucetPage";
import TippedUser from "./pages/TippedUser";
import SearchUser from "./pages/SearchUser";

function App() {
  return (
    <HashRouter>
      <ScrollToTop>
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/"}`}
            component={HomeOne}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/home-one"}`}
            component={HomeOne}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/home-two"}`}
            component={HomeTwo}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/home-three"}`}
            component={HomeThree}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/home-four"}`}
            component={HomeFour}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/about"}`}
            component={About}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/pricing"}`}
            component={Pricing}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/blog"}`}
            component={Blog}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/blog-right-sidebar"}`}
            component={BlogRightSidebar}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/blog-post"}`}
            component={BlogPost}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/contact"}`}
            component={Contact}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/product"}`}
            component={Product}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/how-it-works"}`}
            component={HowItWorks}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/merchants"}`}
            component={Merchants}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/team"}`}
            component={Team}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/wallet"}`}
            component={Wallet}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/login-register"}`}
            component={LoginRegister}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/not-found"}`}
            component={NotFound}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/profile"}`}
            component={Profile}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/faucet-page"}`}
            component={FaucetPage}
          />
             <Route
            path={`${process.env.PUBLIC_URL + "/search"}`}
            component={SearchUser}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/user-wallet/:userId/:wallet"}`}
            render={props => <UserWallet {...props}/>}
          />
             <Route
            exact
            path={`${process.env.PUBLIC_URL + "/user-wallet-user-name/:userId/:userName"}`}
            render={props => <UserWalletByUserName {...props}/>}
          />
               <Route
            exact
            path={`${process.env.PUBLIC_URL + "/tipped-user/:userName"}`}
            render={props => <TippedUser {...props}/>}
          />
          
          <Route exact component={NotFound} />
        </Switch>
      </ScrollToTop>
    </HashRouter>
  );
}

export default App;
