import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";

const TippedUser = () => {
  let { userName } = useParams();
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await axios
        .get(
          `https://1ui1wl9lq0.execute-api.us-east-2.amazonaws.com/dev/user/?username=${userName}`
        )
        .then((response) => {
          console.log(response);
          setUserData(response.data.Data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    })();
  }, [userName]);
  return (
    <Fragment>
      {loading ? (
        <div className="page-loader d-flex align-items-center justify-content-center">
          <div className="d-flex align-self-center mx-auto">
            <Spinner
              className="mr-1 page-spinner"
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
            />
          </div>
        </div>
      ) : (
        <div className="container pt--80">
          <div className="align-items-center">
            <div className="user-container">
              <h1 className="text-white text-center mt-4">Tip Me</h1>
              <div
                class="user-body"
                style={{
                  backgroundImage: `url(${
                    process.env.PUBLIC_URL + "/images/bg/12.png"
                  })`,
                  backgroundRepeat: `no-repeat`,
                  backgroundSize: `cover`,
                }}
              >
                <div class="user-img-container">
                  <div
                    style={{ backgroundImage: `url(${userData.profileImage})` }}
                    className="user-img"
                  />
                </div>
                <div className="user-details">
                  <h2 className="text-center">
                    {userData.firstName} {userData.lastName}
                  </h2>
                  <div className="row">
                    <div className="col-6">
                      <QRCode value={userData.currentWallet} size={140} />
                    </div>
                    <div className="col-6">
                      <Link to={process.env.PUBLIC_URL + "/"}>
                        <img
                          src={process.env.PUBLIC_URL + "/images/logo/1.png"}
                          alt="logo images"
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default TippedUser;
