import React, { useState } from "react";
import UserTable from "../../containers/user-tables/UserTable";

const SearchUserContent = () => {

  const IntialInputs = () => ({
    inputs: {
      country: "",
      city: "",
      district: "",
      establishmentName: "",
      userName: "",
    },
  });
  const handleChange = (e) => {
    debugger
    const { value, name } = e.target;
    const { inputs } = state;

    inputs[name] = value;
    setState({
      ...state,
      inputs,
    });
  };
  const user = [
    { id: 1, name: "adel", establishmentName: "All IN One" },
    { id: 2, name: "glenn", establishmentName: "All IN One" },
  ];
  const [state, setState] = useState(IntialInputs());
  const [users, setUsers] = useState(user);

  return (
    <div className="pt--60 search-container">
      <div className="container">
        <div className="row">
          <div className="col">
            <h5 className="text-left">Search by Country</h5>
          </div>
        </div>
        <div className="row single__account">
          <div className="input__box col-lg-4 col-md-4 col-sm-12">
            <span>Country</span>
            <input
              type="text"
              name="country"
              onChange={(e) => {
                handleChange(e);
              }}
              value={state.inputs.country}
            />
          </div>
          <div className="input__box col-lg-4 col-md-4 col-sm-12 ">
            <span>city</span>
            <input
              type="text"
              name="city"
              onChange={(e) => {
                handleChange(e);
              }}
              value={state.inputs.city}
            />
          </div>
          <div className="input__box col-lg-4 col-md-4 col-sm-12">
            <span>district</span>
            <input
              type="text"
              name="district"
              onChange={(e) => {
                handleChange(e);
              }}
              value={state.inputs.district}
            />
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="row">
              <div className="col">
                <h5 className="text-left">Search by Establishment </h5>
              </div>
            </div>
            <div className="row single__account">
              <div className="input__box col-12">
                <span>Name</span>
                <input
                  type="text"
                  name="establishmentName"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={state.inputs.establishmentName}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="row">
              <div className="col">
                <h5 className="text-left">Search by User </h5>
              </div>
            </div>
            <div className="row single__account">
              <div className="input__box col-12">
                <span>Name</span>
                <input
                  type="text"
                  name="userName"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={state.inputs.userName}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <button className="account__btn w-25">Search</button>
          </div>
        </div>
        <div className="row">
          <div className="col">
             <UserTable userData={users}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchUserContent;
