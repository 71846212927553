import React from "react";
import { Link } from "react-router-dom";

const UserTable = (userData) => {
;
  return (
    <div className="market__table bg--white pt--140 pb--120">
      <div className="container-fluid">
      <div className="row px-0">
          <div className="col px-0">
            <h5 className="text-left">Result: Found {userData.userData.length} Users </h5>
          </div>
        </div>
        <div className="row px-0">
          <div className="col-lg-12 table-responsive px-0">
            <table className="mkt__pre__list">
              <thead>
                <tr>
                  <th className="market-no">
                    <span className="nobr">#No</span>
                  </th>
                  <th className="market-symbol">Name</th>
                  <th className="market-prize">
                    <span className="nobr">Establishment</span>
                  </th>
                  <th className="market-prize">
                    <span className="nobr">&nbsp;</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {userData.userData &&
                  userData.userData.map((single, key) => (
                    <tr key={key}>
                      <td className="market-no">{key+1}</td>
                      <td className="market-symbol">{single.name}</td>
                      <td className="market-prize">
                        {single.establishmentName}
                      </td>
                      <td className="market-dat text-center">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            `/user-wallet-user-name/1/${single.name}`
                          }
                          className="btn btn-table-tip"
                        >
                          Tip
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserTable;
